//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
// Palette
$andys_black: #000000;
$andys_yellow: #f0c900;
$andys_red: #c40009;
$andys_gray: #97979a;
$andys_geen: #68a659;
$lp_blue: #234b96;
$lp_orange: #b0753d;
$lp_yellow: #e4b062;

// Color scheme
$primary: $lp_blue;
$secondary: $andys_gray;
// $success: $andys_geen;
// $danger: $andys_red;
// $dark: $andys_black;
